//Colors

$primary-orange: #F58821;
$secondary-orange: #cb6621;
$font-color-light: #949494;
$main-title-color: #4B525B;
$secondary-title: #7D7D7D;
$border-divider-line-color: #949494; //use for all borders or dividers

//Box shadow value
$card-box-shadow: 5px 5px 19px 5px rgba(0,0,0,0.11) !important;


//Layouts

$mobile-break-point: 61em; //Todo: need to decide a common breakpoint with the Team.
$content-card-max-width: 1300px;//Content Cards in DetailView

//common styles

// use for sub-category for weapons (Example: "pistol")
.product-sub-title {
  color: $secondary-title;
  font-size: 16pt;
  font-weight: 400;
}

//Price Field "Large"
.price-field {
  color: $primary-orange;
  font-size: 24pt;
  font-weight: 700;
}

//caption for attributes in weapon details (Example: "manufacturer")
.product-attribute-caption {
  color: #7D7D7D;
  font-size: 12pt;
}

//values for weapon attributes
.product-attribute-value {
  color: $main-title-color;
  font-size: 15pt;
  font-weight: bold;
  line-height: 1;
  padding-right: .5em;

  &.unset {
    color: #7D7D7D !important;
    font-weight: normal !important;
  }
}

.simple-card-container {
  max-width: $content-card-max-width;
  padding: 2em;
  box-shadow: $card-box-shadow;
  @media(max-width: $mobile-break-point) {
    padding-left: 1em;
  }
}




