.App {
    position: relative;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.page-content {
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin-bottom: 56px;
}

.main-title-blog-home{
    justify-content: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.btn-primary{
    --bs-btn-color: #fff !important;
    --bs-btn-bg: #F58821 !important;
    --bs-btn-border-color: #F58821 !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: #cb6621 !important;
    --bs-btn-hover-border-color: #cb6621 !important;
    --bs-btn-focus-shadow-rgb: 49,132,253 !important;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: #cb6621 !important;
    --bs-btn-active-border-color: #cb6621 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: #fff !important;
    --bs-btn-disabled-bg: #cb6621 !important;
    --bs-btn-disabled-border-color: #cb6621 !important;
}

@media screen and (min-width: 992px){
    .page-content{
        margin-bottom: unset;
    }
}
