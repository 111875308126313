.logo {
  height: 6em;
  transition: height 0.4s;
}

.small-logo{
  height: 3em;
}

.Responsive-Nav-Bar-Wrapper {
  display: none !important;
}

.navbar-container{
  position: sticky !important;
  top: 0;
}

.navbarStyle {
  background-image: url("assets/headerImage.jpg");
  background-blend-mode: overlay;
  width: 100%;
  padding: 0 !important;
}

.nav-link-custom {
  text-decoration: none;

  & a {
    text-decoration: inherit;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
  }
}

.navbar-brand {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  backdrop-filter: brightness(66%);
  text-align: center;
  top: 0;
}

.flex-space-between {
  justify-content: space-between;
}

.Responsive-Nav-Bar {
  justify-content: space-between;
}

.nav-link-custom {
  color: #000 !important;
  font-size: 14pt !important;
  text-decoration: none !important;
  margin-left: auto;

}

.nav-link-custom:hover {
  color: #f3791b !important;
}

.profileButton > button, .navbarButton {
  text-decoration: none !important;
  color: white !important;
  background-color: #f3791b;
  border: none;
}

.profileButton > button:hover {
  background-color: #ff8f15;
}

.dropdown-item {
  text-align: right;
}

#language-list {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.8em;
}

@media screen and (min-width: 992px) {
  .Responsive-Nav-Bar {
    justify-content: center;
    height: 3em;
    border-bottom: 1px solid #e0e0e0;
  }

  .Responsive-Nav-Bar-Wrapper {
    display: flex !important;
  }

  .Mobile-Bottom-Navigation-Wrapper {
    display: none;
  }
}
