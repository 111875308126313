@import "../../styles/common/variables";

$card-height: 150px;
$text-height: 90px;

.blog-card {
  min-height: $card-height;
  border: 1px solid #e0e0e0;
  padding: 0.5em;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow linear 0.2s;

  &:not(:first-child){
    border-top: none;
  }

  &:hover{
    box-shadow: rgba(0,0,0,0.2) 0 0 5px;
  }
}

.blog-card-image {
  width: 100%;
  height: 90px;
  margin: auto;
  object-fit:contain;
}

.regular-blog-title {
  font-size: 20px;
  color: $primary-orange;
}

.regular-blog-description {
  color: $secondary-title;
  min-height: 77px;
  font-size: 12px;
  max-height: calc($text-height - 1em);
  overflow: clip;
  text-overflow: ellipsis;
  & > p{
    height: 100%;
    overflow-y: hidden;
    text-overflow: ellipsis;
  }
}

.reading-time {
  color: $secondary-orange;
}

.post-date {
  color: $font-color-light;
}

.blog-card.hero-post{
  height: 400px;
}

.hero-post-image{
  & img{
    height: unset !important;
    max-height: 200px;
  }
}

@media screen and (min-width: 390px){
  .hero-post-image img{
    max-height: 250px;
  }
}

@media screen and (min-width: 550px){
  .hero-post-image img{
    object-fit: cover;
    max-height: 350px;
  }

  .blog-card.hero-post{
    min-height: 500px;
  }
}

@media screen and (min-width: 750px){
  .hero-post-image img{
    max-height: 450px;
  }

  .blog-card.hero-post{
    height: 600px;
  }
}